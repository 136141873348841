import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import EventHeader from 'components/EventHeader';
import EventAnimation from 'components/EventAnimation';
import Layout from 'components/Layout';
import React, { useEffect, useRef, useState } from 'react';
import { getEvent } from 'services/hasura';
import { useQueryParam, StringParam } from 'use-query-params';
import Row from 'components/Row';
import Col from 'components/Col';
import Cart from 'components/Cart';
import Text from 'components/Text';
import Modal from 'components/Modal';
import TicketSelector from 'components/TicketSelector';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby-link';
import { BaseForm, FormHolder, Select } from 'components/Form';
import { OrderApi } from 'services/api/order-api';
import { useApi } from 'providers/ApiProvider';
import EventTos from 'components/EventTos';
import { getDatesBetween } from 'utils/date';
import moment from 'moment';
import { currencyToText } from 'utils/money';
import CreateTicketAttendant from '../../store/states/CreateTicketAttendant';

const TicketSelectorWrapper = styled.div(() => [
  css`
    background: #e7eaee;
  `,
  tw`rounded-b-md`
]);

const TicketOwnerWrapper = styled(Col)(() => [tw`w-full p-4`]);

const TicketOwnerForm = ({ event, onContinue, submit, methods }) => {
  const submitRef = useRef(null);
  useEffect(() => {
    if (submit) submitRef.current.click();
  }, [submit]);
  const questions = {};
  event.ticket_types.forEach((v) => {
    if (v.questions) {
      v.questions.forEach((q) => {
        questions[q] = '';
      });
    }
  });

  return (
    <TicketOwnerWrapper spacing="sm">
      <Text label="Your Details" large />
      <BaseForm
        pages={CreateTicketAttendant.pages}
        defaultValues={{ questions: Object.keys(questions) }}
        state={{ data: {} }}
        methods={methods}
      />
    </TicketOwnerWrapper>
  );
};

export default function checkout() {
  const { api } = useApi();
  const { getIdTokenClaims } = useAuth0();
  const [id, setID] = useQueryParam('id', StringParam);
  const [step, setStep] = useState('');
  const [lastStep, setLastStep] = useState(null);
  const [event, setEvent] = useState(null);
  const [busy, setBusy] = useState(false);
  const resp: { data: namespace.Data; loading: boolean } = useQuery(getEvent(id));
  const [showModal, setShowModal] = useState(false);
  const [dates, setDates] = useState([]);
  const [day, setDay] = useState(event ? new Date(event.start_date).toString() : null);
  const [days, setDays] = useState([]);
  const [location, setLocation] = useState(null);
  const [items, setItems] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (!resp.loading) {
      const datesBetween = getDatesBetween(
        new Date(resp.data.event.start_date),
        new Date(resp.data.event.end_date)
      );
      const firstDay = moment(new Date(resp.data.event.start_date)).format('YYYY-MM-DD');
      const lastDay = moment(new Date(resp.data.event.end_date)).format('YYYY-MM-DD');
      setEvent(resp.data?.event);
      setDates(datesBetween);
      setDay(new Date(resp.data?.event.start_date).toString());
      setDays([
        firstDay,
        lastDay //datesBetween.length > 1 ? moment(new Date(datesBetween[1])).format('YYYY-MM-DD') : firstDay
      ]);
      let initial = [];
      if (resp.data?.event?.ticket_groups?.length > 0) {
        resp.data?.event?.ticket_groups.forEach((group) => {
          initial = [
            ...initial,
            ...group.ticket_types.map((t) => {
              return {
                id: t.id,
                status: t.status,
                group_id: group.id,
                group_name: group.name,
                group_description: group.description,
                name: t.name,
                quantity: 0,
                price: t.price || 0,
                description: t.description,
                currency: t.currency ? currencyToText[t.currency] : 'KES'
              };
            })
          ];
        });
      } else {
        initial = resp.data?.event?.ticket_types.map((t) => {
          return {
            id: t.id,
            name: t.name,
            status: t.status,
            quantity: 0,
            price: t.price || 0,
            description: t.description,
            currency: t.currency ? currencyToText[t.currency] : 'KES'
          };
        });
      }
      setCurrencies([...new Set(initial.map((item) => item.currency))]);
      setItems(initial.filter((i) => i.status === 'visible'));
    }
  }, [resp.loading]);
  const changeStep = async (data?) => {
    if (lastStep === step) return;
    switch (step) {
      case '':
        if (event?.venues?.length > 0 && !location) {
          window.alert('Please choose a location');
          break;
        } else if (event?.tos?.length > 'b64:PHA+PC9wPg=='.length) {
          setStep('tos');
          setShowModal(true);
          setLastStep('');
          break;
        }
        setStep('fillAttendant');
        setLastStep('');
        break;
      case 'tos':
        setStep('fillAttendant');
        setLastStep('tos');
        break;
      case 'fillAttendant':
        if (data) {
          const questions = {};
          event.ticket_types.forEach((v) => {
            if (v.questions) {
              v.questions.forEach((q, i) => {
                questions[q] = data[`questions-${i}`];
              });
            }
          });
          const claims = await getIdTokenClaims();
          const orderAPI = new OrderApi(api, claims ? claims.__raw : null);
          setBusy(true);
          const total = Object.values(items).reduce(
            (t, { price, quantity }) => {
              return { price: price * quantity + t.price, quantity: t.quantity + quantity };
            },
            { price: 0, quantity: 0 }
          );
          //   TODO: hack
          total.subtotal = total.price;
          total.taxes = 0;
          total.total = total.price;
          const mergedData = {
            ...data,
            ...total,
            day: moment(day).format(),
            days: days.map((day) => moment(day).format()),
            venue: location ? location.venue : event.venue,
            questions,
            event,
            items: items
              .filter((props) => props.quantity > 0)
              .map((props) => ({
                name: props.name,
                quantity: props.quantity,
                price: props.price,
                product: 'ticket',
                product_id: props.id,
                currency: currencyToText[props.currency]
              }))
          };
          const result = await orderAPI.createOrder(mergedData);
          setBusy(false);
          if (result.kind === 'bad-data') {
            alert('Failed: Please try again');
          } else if (total.price > 0) {
            navigate(`/booking/choose-payment/?id=${result.order.insert_orders_one.id}`, {
              replace: true
            });
          } else {
            navigate(`/booking/complete/?id=${result.order.insert_orders_one.id}`, {
              replace: true
            });
            // setDetails(data);
          }
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Layout>
        {resp.loading ? (
          <EventAnimation />
        ) : (
          <FormHolder onSubmit={changeStep}>
            {({ methods }) => (
              <Col>
                <EventHeader event={resp.data.event} />
                <TicketSelectorWrapper>
                  <Row collapse spacing="sm">
                    {step === 'fillAttendant' ? (
                      <TicketOwnerForm event={event} methods={methods} busy={busy} />
                    ) : (
                      resp.data.event && (
                        <TicketSelector
                          event={event}
                          dates={resp.data.event.event_type !== 'single_day' ? dates : []}
                          selectedDay={day}
                          selectedDays={days}
                          onSelectedDays={(e) => {
                            setDays(e.value);
                          }}
                          onDayChange={(event) => {
                            setDay(event.value);
                          }}
                          onItemChange={(item, v) => {
                            const ix = items.findIndex((i) => i.id === item.id);
                            const newItems = [...items];
                            newItems[ix].quantity = v;
                            setItems(newItems.filter((i) => i.status === 'visible'));
                          }}
                          selectedLocation={location}
                          onLocationChange={(e) => setLocation(e)}
                          items={items}
                          busy={busy}
                        />
                      )
                    )}
                    <Cart
                      event={resp.data.event}
                      items={items}
                      onComplete={changeStep}
                      busy={busy}
                      day={day}
                      days={days}
                      location={location}
                    />
                  </Row>
                </TicketSelectorWrapper>
              </Col>
            )}
          </FormHolder>
        )}
      </Layout>
      {showModal && event.tos && (
        <Modal
          closeModal={() => {
            setShowModal(false);
          }}
        >
          <EventTos
            event={event}
            onAccept={(e) => {
              e.preventDefault();

              setShowModal(false, changeStep);
            }}
            onCancel={() => {
              setShowModal(false);
              setStep('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
